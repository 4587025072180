import config from "./config.json";

export enum HTTP_METHOD {
    GET = "get",
    POST = "post",
    PUT = "put",
    PATCH = "patch",
    DELETE = "delete"
}

export enum REDIRECT_ERROR_TYPE {
    unauthorized = "unauthorized"
}

export const ADMIN_ROLE: string = 'Admin';

export const REACT_APP_STS_AUTHORITY: string = config.REACT_APP_STS_AUTHORITY;
export const REACT_APP_CLIENT_ID: string = config.REACT_APP_CLIENT_ID;
export const REACT_APP_RESPONSE_TYPE: string = config.REACT_APP_RESPONSE_TYPE;
export const REACT_APP_CLIENT_SCOPE: string = config.REACT_APP_CLIENT_SCOPE;
export const REACT_APP_LOGIN_URL: string = config.REACT_APP_LOGIN_URL;
