import React, { useState } from 'react';
import { Box, IconButton, ListItemText, Menu, MenuItem, Divider, ListItemIcon, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LogoutIcon from '@mui/icons-material/Logout';
import authService from '../services/AuthService';
import { useCurrentUser } from '../app/useCurrentUser';
import { REACT_APP_CLIENT_ID, REACT_APP_LOGIN_URL } from '../constants';
import { useCurrentUserApplications } from '../app/useCurrentUserApplications';

export default function Header(props: any) {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [_, currentUser] = useCurrentUser();
    const userApplications = useCurrentUserApplications();

    const menuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    }

    return (
        <React.Fragment>
            <Box {...props} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' bgcolor="#0078d7">
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Box component='img' src={`${window.location.origin}/admin-logo-white.png`} m={1} maxWidth={200} />
                </Box>
                <Box>
                    {currentUser &&
                        <React.Fragment>
                            <Box component="span" color="white">
                                {currentUser && `${currentUser.firstName} ${currentUser.lastName}`}
                            </Box>
                            <IconButton
                                size="large"
                                sx={{ color: "white" }}
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget)
                                    setMenuOpen(true);
                                }}
                            >
                                <AccountCircleIcon fontSize='large' />
                            </IconButton>
                        </React.Fragment>
                    }
                </Box>
            </Box>

            <Menu
                open={menuOpen}
                anchorEl={anchorEl}
                onClose={menuClose}
            >
                <MenuItem onClick={() => { menuClose(); window.open(`${REACT_APP_LOGIN_URL}/account`, '_blank'); }}>
                    <ListItemIcon>
                        <OpenInNewIcon />
                    </ListItemIcon>
                    <ListItemText>
                        View Account
                    </ListItemText>
                </MenuItem>
                {userApplications && userApplications.length > 1 &&
                    <Box>
                        <Divider />
                        <ListItemText sx={{ ml: 2 }}>
                            <Typography variant='h6'>Applications</Typography>
                        </ListItemText>
                        {userApplications.filter(a => a.clientId !== REACT_APP_CLIENT_ID).map((application, i) => (
                            <MenuItem onClick={() => { menuClose(); window.open(`${application.clientUri}`, '_blank'); }} key={i}>
                                <ListItemIcon>
                                    <OpenInNewIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    {application.clientName}
                                </ListItemText>
                            </MenuItem>
                        ))}
                    </Box>
                }
                <Divider />
                <MenuItem onClick={async () => { menuClose(); await authService.logout(); }}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText>Log Out</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
